#error-screen {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: var(--background);

  .error-response-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding: 60px 10% 0;
    color: var(--text);

    .button {
      width: max-content;
      border-color: var(--text);
    }
  }
}
