// Banner
.bannerRegular {
  font-size: var(--font-banner);
  font-weight: var(--font-normal);
  letter-spacing: 0;
}

.bannerMedium {
  font-size: var(--font-banner);
  font-weight: var(--font-medium);
  letter-spacing: 0;
}

.bannerBold {
  font-size: var(--font-banner);
  font-weight: var(--font-bold);
  letter-spacing: 0;
}

// Heading
.headingRegular {
  font-size: var(--font-heading);
  font-weight: var(--font-normal);
  letter-spacing: 0;
}

.headingMedium {
  font-size: var(--font-heading);
  font-weight: var(--font-medium);
  letter-spacing: 0;
}

.headingBold {
  font-size: var(--font-heading);
  font-weight: var(--font-bold);
  letter-spacing: 0;
}

// XXL
.xxlargeRegular {
  font-size: var(--font-xxl);
  font-weight: var(--font-normal);
  letter-spacing: 0;
}

.xxlargeMedium {
  font-size: var(--font-xxl);
  font-weight: var(--font-medium);
  letter-spacing: 0;
}

.xxlargeBold {
  font-size: var(--font-xxl);
  font-weight: var(--font-bold);
  letter-spacing: 0;
}

// XL
.xlargeRegular {
  font-size: var(--font-xl);
  font-weight: var(--font-normal);
  letter-spacing: 0;
}

.xlargeMedium {
  font-size: var(--font-xl);
  font-weight: var(--font-medium);
  letter-spacing: 0;
}

.xlargeBold {
  font-size: var(--font-xl);
  font-weight: var(--font-bold);
  letter-spacing: 0;
}

// Large
.largeRegular {
  font-size: var(--font-lg);
  font-weight: var(--font-normal);
  letter-spacing: 0;
}

.largeMedium {
  font-size: var(--font-lg);
  font-weight: var(--font-medium);
  letter-spacing: 0;
}

.largeBold {
  font-size: var(--font-lg);
  font-weight: var(--font-bold);
  letter-spacing: 0;
}

// Medium
.mediumRegular {
  font-size: var(--font-md);
  font-weight: var(--font-normal);
  letter-spacing: 0;
}

.mediumMedium {
  font-size: var(--font-md);
  font-weight: var(--font-medium);
  letter-spacing: 0;
}

.mediumBold {
  font-size: var(--font-md);
  font-weight: var(--font-bold);
  letter-spacing: 0;
}

// Small
.smallRegular {
  font-size: var(--font-sm);
  font-weight: var(--font-normal);
  letter-spacing: 0;
}

.smallMedium {
  font-size: var(--font-sm);
  font-weight: var(--font-medium);
  letter-spacing: 0;
}

.smallBold {
  font-size: var(--font-sm);
  font-weight: var(--font-bold);
  letter-spacing: 0;
}

// XS
.xsmallRegular {
  font-size: var(--font-xs);
  font-weight: var(--font-normal);
  letter-spacing: 0;
}

.xsmallMedium {
  font-size: var(--font-xs);
  font-weight: var(--font-medium);
  letter-spacing: 0;
}

.xsmallBold {
  font-size: var(--font-xs);
  font-weight: var(--font-bold);
  letter-spacing: 0;
}

// XXS
.xxsmallRegular {
  font-size: var(--font-xxs);
  font-weight: var(--font-normal);
  letter-spacing: 0;
}

.xxsmallMedium {
  font-size: var(--font-xxs);
  font-weight: var(--font-medium);
  letter-spacing: 0;
}

.xxsmallBold {
  font-size: var(--font-xxs);
  font-weight: var(--font-bold);
  letter-spacing: 0;
}
