@import './variables.scss';

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$screen-xl}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (max-width: ($screen+'px')) {
    @content;
  }
}
