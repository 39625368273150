@import './mixins.scss';
@import './variables.scss';

// Global styling

// Gets rid of annoying webpack error overlay
#webpack-dev-server-client-overlay {
  display: none !important;
}

.clickable {
  cursor: pointer;
  transition: $transitionSpeed;

  &:hover {
    opacity: 0.6;
  }
}

.no-select {
  user-select: none;
}

.common-modal {
  width: 40vw;

  @include xl {
    width: 60vw;
  }

  @include md {
    width: 80vw;
  }

  @include sm {
    width: 80vw;
  }
}

.response-block-wrapper {
  display: flex;
  flex-direction: column;
  // animation-name: fade-in-right;
  // animation-duration: var(--normal-animation); // Duration of the animation
  // animation-fill-mode: both; // Keeps the element visible after the animation
  margin-bottom: var(--spacing-md);

  &.response-block-last-response {
    margin-bottom: var(--spacing-sm);
  }

  .client-response {
    background-color: var(--background-light);
  }

  .server-response {
    background-color: var(--background-light);
  }

  .response-block-common-style {
    display: flex;
    color: var(--text);
    padding: var(--spacing-md) var(--spacing-lg);
    border-radius: var(--border-radius-medium);
    line-height: 1.5;
    // box-shadow: inset 0px 0px 0px 2px var(--background-dark);
    box-shadow: var(--box-shadow);

    p {
      margin: 0;
    }
  }
}

// Padding
.p-b-xxs {
  padding-bottom: var(--spacing-xxs);
}

.p-b-xs {
  padding-bottom: var(--spacing-xs);
}

.p-b-sm {
  padding-bottom: var(--spacing-sm);
}

.p-b-md {
  padding-bottom: var(--spacing-md);
}

.p-b-lg {
  padding-bottom: var(--spacing-lg);
}

.p-b-xl {
  padding-bottom: var(--spacing-xl);
}

.p-b-xxl {
  padding-bottom: var(--spacing-xxl);
}

.p-t-xxs {
  padding-top: var(--spacing-xxs);
}

.p-t-xs {
  padding-top: var(--spacing-xs);
}

.p-t-sm {
  padding-top: var(--spacing-sm);
}

.p-t-md {
  padding-top: var(--spacing-md);
}

.p-t-lg {
  padding-top: var(--spacing-lg);
}

.p-t-xl {
  padding-top: var(--spacing-xl);
}

.p-t-xxl {
  padding-top: var(--spacing-xxl);
}

.p-l-xxs {
  padding-left: var(--spacing-xxs);
}

.p-l-xs {
  padding-left: var(--spacing-xs);
}

.p-l-sm {
  padding-left: var(--spacing-sm);
}

.p-l-md {
  padding-left: var(--spacing-md);
}

.p-l-lg {
  padding-left: var(--spacing-lg);
}

.p-l-xl {
  padding-left: var(--spacing-xl);
}

.p-l-xxl {
  padding-left: var(--spacing-xxl);
}

.p-r-xxs {
  padding-right: var(--spacing-xxs);
}

.p-r-xs {
  padding-right: var(--spacing-xs);
}

.p-r-sm {
  padding-right: var(--spacing-sm);
}

.p-r-md {
  padding-right: var(--spacing-md);
}

.p-r-lg {
  padding-right: var(--spacing-lg);
}

.p-r-xl {
  padding-right: var(--spacing-xl);
}

.p-r-xxl {
  padding-right: var(--spacing-xxl);
}

// Margin
.m-b-xxs {
  margin-bottom: var(--spacing-xxs);
}

.m-b-xs {
  margin-bottom: var(--spacing-xs);
}

.m-b-sm {
  margin-bottom: var(--spacing-sm);
}

.m-b-md {
  margin-bottom: var(--spacing-md);
}

.m-b-lg {
  margin-bottom: var(--spacing-lg);
}

.m-b-xl {
  margin-bottom: var(--spacing-xl);
}

.m-b-xxl {
  margin-bottom: var(--spacing-xxl);
}

.m-t-xxs {
  margin-top: var(--spacing-xxs);
}

.m-t-xs {
  margin-top: var(--spacing-xs);
}

.m-t-sm {
  margin-top: var(--spacing-sm);
}

.m-t-md {
  margin-top: var(--spacing-md);
}

.m-t-lg {
  margin-top: var(--spacing-lg);
}

.m-t-xl {
  margin-top: var(--spacing-xl);
}

.m-t-xxl {
  margin-top: var(--spacing-xxl);
}

.m-l-xxs {
  margin-left: var(--spacing-xxs);
}

.m-l-xs {
  margin-left: var(--spacing-xs);
}

.m-l-sm {
  margin-left: var(--spacing-sm);
}

.m-l-md {
  margin-left: var(--spacing-md);
}

.m-l-lg {
  margin-left: var(--spacing-lg);
}

.m-l-xl {
  margin-left: var(--spacing-xl);
}

.m-l-xxl {
  margin-left: var(--spacing-xxl);
}

.m-r-xxs {
  margin-right: var(--spacing-xxs);
}

.m-r-xs {
  margin-right: var(--spacing-xs);
}

.m-r-sm {
  margin-right: var(--spacing-sm);
}

.m-r-md {
  margin-right: var(--spacing-md);
}

.m-r-lg {
  margin-right: var(--spacing-lg);
}

.m-r-xl {
  margin-right: var(--spacing-xl);
}

.m-r-xxl {
  margin-right: var(--spacing-xxl);
}
