@import './variables.scss';

/** For overriding prime components and styles **/
button {
  height: 48px;
  border-radius: var(--border-radius-small);
  justify-content: center;

  .p-button-icon {
    margin-right: var(--spacing-sm);
  }
}

.p-datatable {
  button {
    transition: $transitionSpeed;
  }

  .p-datatable-wrapper {
    border-top-right-radius: var(--border-radius-medium);
    border-top-left-radius: var(--border-radius-medium);
  }

  .p-paginator-bottom {
    border-bottom-right-radius: var(--border-radius-medium);
    border-bottom-left-radius: var(--border-radius-medium);
  }
}

// Toolbar menu items
.p-menuitem-icon,
.p-menuitem-text {
  color: var(--text);
}

input {
  &::placeholder {
    color: var(--text);
    opacity: 0.75;
  }
}

.p-menu {
  padding: 0.5rem;
  border-radius: var(--border-radius-medium);

  ul {
    li {
      margin-bottom: 10px;

      .p-menuitem-link {
        border-radius: var(--border-radius-medium);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.p-dialog {
  // Header icon
  .p-dialog-header-icon {
    color: var(--text);
    transition: 0.25s ease;

    &:hover {
      opacity: 0.6;
    }
  }

  // Dialog text
  .p-confirm-dialog-message {
    color: var(--text);
  }

  // Dialog reject button
  .p-confirm-dialog-reject {
    color: var(--text);
  }

  // Dialog accept button
  .p-confirm-dialog-accept {
    font-weight: bold;
  }
}
